import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageLayout } from "../../layouts/page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "privacy-policy"
    }}>{`Privacy policy`}</h1>
    <h2 {...{
      "id": "entity"
    }}>{`Entity`}</h2>
    <p>{`Lleed & Partners SàRL, also referred to as Lleed & Partners, L&P, "We" in this document is a swiss law, limited liability company.`}</p>
    <p><a parentName="p" {...{
        "href": "/legal/#entity"
      }}>{`More information about Lleed & Partners`}</a></p>
    <h2 {...{
      "id": "information-we-collect"
    }}>{`Information we collect`}</h2>
    <p>{`When using this website, Lleed & Partners may collect the following data about you and your usage.`}</p>
    <h3 {...{
      "id": "analytics"
    }}>{`Analytics`}</h3>
    <p>{`When browsing this website, and if you have opted in for analytics collection, we will collect the following information:`}</p>
    <ul>
      <li parentName="ul">{`Device type (Desktop or Mobile)`}</li>
      <li parentName="ul">{`Approximate location (Country/Region)`}</li>
      <li parentName="ul">{`Web analytics data, which includes which pages you visited, what you clicked and how deep you scrolled.`}</li>
    </ul>
    <p>{`These information will use an anonymous device identifier, that is strictly private and not shared with any third parties.
If you have not opted in for analytics collection, or have opted out, we will not collect your usage data.`}</p>
    <p>{`These analytics allow us to better understand which parts of the website are relevant, to improve the user experience in the future. They are not used for marketing or advertising purposes.`}</p>
    <h3 {...{
      "id": "contact"
    }}>{`Contact`}</h3>
    <p>{`When contacting us using a form on this website, we will collect the following information:`}</p>
    <ul>
      <li parentName="ul">{`Name`}</li>
      <li parentName="ul">{`Phone number`}</li>
      <li parentName="ul">{`Company name`}</li>
      <li parentName="ul">{`Other message contents as clearly stated in the form fields`}</li>
    </ul>
    <p>{`Given that these forms are then processed by Netlify before being sent to our notification channels, you must allow automated treatment of your data. This data is then deleted by Netlify in a timely manner.
If you would prefer not to allow such treatment, please contact us by mail, email or phone.`}</p>
    <p>{`This information allow us to get back in touch with you with the best suited follow-up.`}</p>
    <h2 {...{
      "id": "information-deletion-and-modification"
    }}>{`Information deletion and modification`}</h2>
    <p>{`A user can, at any time, request to view, modify or delete part or all of its data that we have stored by ourselved and on third party services. To amend your data, please use our `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`contact portal`}</a>{`.`}</p>
    <h2 {...{
      "id": "cookies"
    }}>{`Cookies`}</h2>
    <h3 {...{
      "id": "ajs_anonymous_id"
    }}>{`ajs_anonymous_id`}</h3>
    <p>{`If consents were granted, this cookie stores the unique device identifier used by other analytics service to identify a same device.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Category:`}</strong>{` Analytics`}</li>
      <li parentName="ul"><strong parentName="li">{`Collected:`}</strong>{` When consent for any of the data collection services is granted`}</li>
      <li parentName="ul"><strong parentName="li">{`Used by:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://segment.com/legal/privacy/"
        }}>{`Segment`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Shared with:`}</strong>{` Google Tag Manager`}</li>
      <li parentName="ul"><strong parentName="li">{`Expiry:`}</strong>{` 1 year`}</li>
    </ul>
    <h3 {...{
      "id": "factor-segment-consents"
    }}>{`factor-segment-consents`}</h3>
    <p>{`Stores the current consent preferences.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Category:`}</strong>{` Consents`}</li>
      <li parentName="ul"><strong parentName="li">{`Collected:`}</strong>{` When consent for any of the data collection services is granted or rejected`}</li>
      <li parentName="ul"><strong parentName="li">{`Used by:`}</strong>{` Lleed & Partners`}</li>
      <li parentName="ul"><strong parentName="li">{`Expiry:`}</strong>{` 1 month`}</li>
    </ul>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      